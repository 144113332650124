export default {
  apiKey: 'AIzaSyBxt5DGCfDJPWvEEuxKdtq45xhcatTvrko',
  authDomain: 'cricket-ba839.firebaseapp.com',
  databaseURL: 'https://cricket-ba839.firebaseio.com',
  projectId: 'cricket-ba839',
  storageBucket: 'cricket-ba839.appspot.com',
  messagingSenderId: '954241909903',
  appId: '1:954241909903:web:48328a5ef9d6fbacc11cfa',
  measurementId: 'G-MZNRMR4Y93',
};
